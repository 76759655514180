import React from "react";

import "../../assets/sass/main.scss";

const Footer = () => (
  <footer id="footer" className="wrapper style1-alt">
    <div className="inner">
      <ul className="menu">
        <li>
          <li>
            Design with ♥ by{" "}
            <a
              href="https://antilleansoftware.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Antillean Software
            </a>
          </li>
        </li>
      </ul>
    </div>
  </footer>
);

export default Footer;
